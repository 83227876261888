import React from 'react'

const footer = () => {
  return (
    <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 footer-copyright">
              <p className="mb-0">Copyright 2021-22 © viho All rights reserved.</p>
            </div>
            <div className="col-md-6">
              <p className="pull-right mb-0">Hand crafted &amp; made with <i className="fa fa-heart font-secondary" /></p>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default footer