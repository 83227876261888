import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Common/assets/images/login/1.jpg'


import './Common/assets/css/style.css';
import './Common/assets/css/fontawesome.css';
import './Common/assets/css/icofont.css';
import './Common/assets/css/themify.css';
import './Common/assets/css/flag-icon.css';
import './Common/assets/css/feather-icon.css';
import './Common/assets/css/sweetalert2.css';
import './Common/assets/css/bootstrap.css';
import './Common/assets/css/color-1.css';
import './Common/assets/css/responsive.css';
import './Common/assets/images/favicon.png';
import './Common/assets/css/svg-icon.css';
import './Common/assets/css/animate.css';
import './Common/assets/css/chartist.css';
import './Common/assets/css/date-picker.css';
import './Common/assets/css/prism.css';
import './Common/assets/css/vector-map.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import './Common/assets/css/aos.css'
import './Common/assets/css/button-builder.css'
import './Common/assets/css/calendar.css'

// import './Common/assets/css/color-2.css'
// import './Common/assets/css/color-3.css'
// import './Common/assets/css/color-4.css'
// import './Common/assets/css/color-5.css'
// import './Common/assets/css/color-6.css'
import './Common/assets/css/datatable-extension.css'
import './Common/assets/css/datatables.css'
import './Common/assets/css/daterange-picker.css'
import './Common/assets/css/dropzone.css'
import './Common/assets/css/image-cropper.css'
import './Common/assets/css/jkanban.css'
import './Common/assets/css/jsgrid.css'
import './Common/assets/css/mapsjs-ui.css'
import './Common/assets/css/owlcarousel.css'
import './Common/assets/css/owlcarousel.css'
import './Common/assets/css/page-builder.css'
import './Common/assets/css/print.css'
import './Common/assets/css/range-slider.css'
import './Common/assets/css/rating.css'
import './Common/assets/css/scrollable.css'
import './Common/assets/css/select2.css'
import './Common/assets/css/simple-mde.css'
import './Common/assets/css/slick-theme.css'
import './Common/assets/css/sticky.css'
import './Common/assets/css/summernote.css'
import './Common/assets/css/themify.css'
import './Common/assets/css/timepicker.css'
import './Common/assets/css/todo.css'
import './Common/assets/css/tour.css'
// import './Common/assets/css/tree.css'
import './Common/assets/css/vertical-menu.css'
import './Common/assets/css/vertical-menu.css'
import './Common/assets/css/whether-icon.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
