import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage'


function PrivateRoutes() {

    return (

        reactLocalStorage.get("loginstatus") ? <Outlet /> : <Navigate to="/login" />

    )
}

export default PrivateRoutes