import React, { useEffect, useState } from 'react'
import Header from '../../Common/Layout/header'
import SideBar from '../../Common/Layout/sideBar'
import Footer from '../../Common/Layout/footer'
import { MDBDataTable } from "mdbreact";
import ProductImage from '../../Common/assets/images/product/16.jpg'
import HttpClient from '../../utils/HttpClient';
import SweetAlert from "react-bootstrap-sweetalert"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QRCode from "qrcode";
import { async } from "@firebase/util";
import { db } from '../../firbase';
// import { db } from "./firebase";
// import { set,ref,push } from "firebase/database";
import { Button } from 'bootstrap';
import { set, push, ref, query, orderByValue, onValue, get, orderByChild, equalTo, update, remove } from 'firebase/database';
// import { db } from '../../firbase';
import { Link } from "react-router-dom"
import { FaTrashAlt } from 'react-icons/fa';


const Categories = () => {
   
    const [catName_eng, setCatName_Eng] = useState("")
    const [catName_hin, setCatName_hin] = useState("")
    const [catName_guj, setCatName_guj] = useState("")
    const [cat_image, setCatImage] = useState()
    const [preview, setpreview] = useState([]);
    const [categoryData, setCategoryData] = useState([])
    const [confirm_alert, setconfirm_alert] = useState(false)
    const [delId, setdelid] = useState("")
    const [edit, setEdit] = useState(false)
    const [editId, setEditiD] = useState(false)

    const [position, setPosition] = useState("")
    const [heading, setHeading] = useState("")
    const [Line1, setLine1] = useState("")
    const [Line2, setLine2] = useState("")
    const [Line3, setLine3] = useState("")
    const [line4, setLine4] = useState("")
    const [button, setButton] = useState("")
    const [id, setId] = useState("")
    const [hint, setHint] = useState("")
    const [AllData, setAllData] = useState([])
    const [QrData, setQrData] = useState([])



    const fetchQr = () => {
        const starCountRef = ref(db, 'qr');
        // const starCountRef = ref(db, 'chatbox/');
        onValue(starCountRef, snapSort => {
            const data = snapSort.val()
            // console.log(data)
            let Alldata1 = []
            for (const item in data) {
                Alldata1 = [...Alldata1, { id: item, ...data[item] }]
            }

            setAllData(Alldata1)
            let arr = []
            let i = 1
            Alldata1.forEach((element) => {
                let obj = {
                    sl: i,
                    id: element.id,
                    count: element.count,
                    img: <img src={element.img} />,

                    action: (
                        <div className="d-flex">
                            <button className='btn ' onClick={() => {
                                setEdit(true)
                                editData(element.id)
                                window.scroll(0, 0)
                            }}>
                                <i className="fa fa-edit"></i>

                            </button>

                            <button
                                type="button"
                                className="btn  btn-danger ml-1"
                                onClick={() => {
                                    setdelid(element.id)
                                    setconfirm_alert(true)
                                }}
                            >
                                {/* <i className="fas fa-trash-alt"></i>
                                 */}
                                 <FaTrashAlt/>
                            </button>
                        </div>
                    ),

                }
                i++
                arr.push(obj)
            })
            setQrData(arr)

        })

    }
    const AddQrCode = async (e) => {
        e.preventDefault()
        if (position != "" && id != "" && hint != "" && button != "" && heading != "" && Line1 != "") {
            let data = {

            }
            console.log("send", data)
            if (edit) {
                try {
                    // let time4 = new Date().getTime().toString()
                    const reference = ref(db, "qr/" + id)
                    const newPostRef = push(reference);

                    let res = await QRCode.toDataURL(id)
                    // console.log(reference)
                    update(reference, {
                        img: res,
                        id: id,
                        count: Number(position),
                        h1: heading ? heading : null,
                        l1: Line1 ? Line1 : null,
                        l2: Line2 ? Line2 : null,
                        l3: Line3 ? Line3 : null,
                        l4: line4 ? line4 : null,
                        btn: button ? button : null,
                        data: hint ? hint : null

                    })

                    toast.success("edited Successfully")
                    setEdit(false)
                    setPosition("")
                    setHeading("")
                    setLine1("")
                    setLine2("")
                    setLine3("")
                    setLine4("")
                    setButton("")
                    setHint("")
                    setId("")
                    fetchQr()

                }
                catch (error) {
                    alert(error)
                }
            }
            else {
                let find = QrData.find((item) => item.count == position)
                if (find) {
                    toast.warning("Position already exist")
                }
                else {
                    try {
                        // let time4 = new Date().getTime().toString()
                        const reference = ref(db, "qr/" + id)
                        const newPostRef = push(reference);

                        let res = await QRCode.toDataURL(id)
                        // console.log(reference)
                        set(reference, {
                            img: res,
                            id: id,
                            count: Number(position),
                            h1: heading ? heading : null,
                            l1: Line1 ? Line1 : null,
                            l2: Line2 ? Line2 : null,
                            l3: Line3 ? Line3 : null,
                            l4: line4 ? line4 : null,
                            btn: button ? button : null,
                            data: hint ? hint : null

                        })

                        toast.success("Added Successfully")
                        fetchQr()
                        setPosition("")
                        setHeading("")
                        setLine1("")
                        setLine2("")
                        setLine3("")
                        setLine4("")
                        setButton("")
                        setHint("")
                        setId("")

                    }
                    catch (error) {
                        alert(error)
                    }
                }


            }

        }
        else {
            toast.warning("All feilds are required")
        }

    }


    const editData = (id) => {
        // console.log("filter", AllData)

        const filter = AllData.filter((item) => item.id == id)
        // console.log("filter", filter)
        setPosition(filter[0].count)
        setHeading(filter[0].h1 ? filter[0].h1 : "")
        setButton(filter[0].btn ? filter[0].btn : "")
        setLine1(filter[0].l1 ? filter[0].l1 : "")
        setLine2(filter[0].l2 ? filter[0].l2 : "")
        setLine3(filter[0].l3 ? filter[0].l3 : "")
        setLine4(filter[0].l4 ? filter[0].l4 : "")
        setHint(filter[0].data ? filter[0].data : "")
        setId(filter[0].id ? filter[0].id : "")

    }

    const data = {
        columns: [
            // {
            //     label: "Sl.",
            //     field: "sl",
            //     sort: "asc",
            //     width: 150,
            // },
            {
                label: "Position",
                field: "count",
                sort: "asc",
                width: 270,
            },
            {
                label: "Id",
                field: "id",
                sort: "asc",
                width: 270,
            },
            {
                label: "Image",
                field: "img",
                sort: "asc",
                width: 270,
            },
            // {
            //     label: "Image",
            //     field: "image",
            //     sort: "asc",
            //     width: 270,
            // },


            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 100,
            },
        ],
        rows: QrData
    };



    const categoryDeleteHandle = async () => {
        if (delId != "") {
            try {
                // let time4 = new Date().getTime().toString()
                const reference = ref(db, "qr/" + delId)
                const newPostRef = push(reference);

                console.log(reference)
                remove(reference)

                toast.success("Deleted Successfully")
                setEdit(false)
                fetchQr()

            }
            catch (error) {
                alert(error)
            }
        }
    }
    const downloadImage = (url) => {
        // console.log(url)
        window.open(url)
    }

    useEffect(() => {
        fetchQr()
    }, [edit])
    return (
        <div>
            <ToastContainer />
            <div class="page-wrapper compact-wrapper" id="pageWrapper">
                <div className="page-main-header">
                    <Header />
                </div>
                <div className="page-body-wrapper sidebar-icon">
                    <div className="page-body">

                        <div className="card">
                            <div className="card-header pb-0">
                                <h5> Add Qr</h5>
                            </div>
                            <form className="form theme-form">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label class="col-sm-3 col-form-label">Qr Position</label>
                                                    <div class="col-sm-9">
                                                        <input className="form-control" type="number" placeholder="" value={position} onChange={(val) => setPosition(val.target.value)} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label class="col-sm-3 col-form-label">Page Heading</label>
                                                    <div class="col-sm-9">
                                                        <input className="form-control" type="text" placeholder="" value={heading} onChange={(val) => setHeading(val.target.value)} />

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mb-3">
                                                <div class="row">
                                                    <label class="col-sm-3 col-form-label">Line 1</label>
                                                    <div class="col-sm-9">
                                                        <input className="form-control" type="text" placeholder="" value={Line1} onChange={(val) => setLine1(val.target.value)} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label class="col-sm-3 col-form-label">Line 2</label>
                                                    <div class="col-sm-9">
                                                        <input className="form-control" type="text" placeholder="" value={Line2} onChange={(val) => setLine2(val.target.value)} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label class="col-sm-3 col-form-label">Line 3</label>
                                                    <div class="col-sm-9">
                                                        <input className="form-control" type="text" placeholder="" value={Line3} onChange={(val) => setLine3(val.target.value)} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label class="col-sm-3 col-form-label">Line 4</label>
                                                    <div class="col-sm-9">
                                                        <input className="form-control" type="text" placeholder="" value={line4} onChange={(val) => setLine4(val.target.value)} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label class="col-sm-3 col-form-label">Button Text</label>
                                                    <div class="col-sm-9">
                                                        <input className="form-control" type="text" placeholder="" value={button} onChange={(val) => setButton(val.target.value)} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label class="col-sm-3 col-form-label">Qr Hint</label>
                                                    <div class="col-sm-9">
                                                        <input className="form-control" type="text" placeholder="" value={hint} onChange={(val) => setHint(val.target.value)} />

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-3">
                                                <div class="row">
                                                    <label class="col-sm-3 col-form-label">Qr ID</label>
                                                    <div class="col-sm-9">
                                                        <input className="form-control" type="text" placeholder="" value={id} onChange={(val) => setId(val.target.value)} />

                                                    </div>
                                                </div>
                                            </div>


                                            {preview != "" ? (
                                                <img
                                                    src={preview}
                                                    height="100px"
                                                    widtth="100px"
                                                />
                                            ) : null}

                                        </div>
                                    </div>
                                </div>




                                <div className="card-footer text-end">
                                    <div className="col-sm-9 offset-sm-3">
                                        <button className="btn btn-primary" type="submit" onClick={AddQrCode} >Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="container-fluid list-products">
                            <div className="row">
                                {/* Individual column searching (text inputs) Starts*/}
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header pb-0">
                                            <h5>Qr Image</h5>
                                        </div>
                                        <div className="card-body">
                                            {/* <div className="table-responsive product-table">
                                                <table className="display" id="basic-1">
                                                    <thead>
                                                        <tr>
                                                            <th width="50%">Sl.</th>
                                                            <th width="50%">Name</th>
                                                            <th width="100%">Description</th>
                                                            <th width="100%">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>Global News</td>
                                                            <td>News</td>
                                                            <td>
                                                                <button className="btn btn-danger btn-xs" type="button" data-original-title="btn btn-danger btn-xs" title>Delete</button>
                                                                <button className="btn btn-primary btn-xs" type="button" data-original-title="btn btn-danger btn-xs" title>Edit</button>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div> */}
                                            <MDBDataTable responsive bordered data={data} />
                                        </div>
                                    </div>
                                </div>
                                {/* Individual column searching (text inputs) Ends*/}
                            </div>
                        </div>
                    </div>
                </div>


                <SideBar />
                <Footer />
            </div>
            {confirm_alert ? (
                <SweetAlert
                    title="Are you sure you want to Delete?"
                    warning
                    showCancel
                    confirmButtonText="Yes, delete it!"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        categoryDeleteHandle()
                        setconfirm_alert(false)
                    }}
                    onCancel={() => setconfirm_alert(false)}
                >
                    You won't be able to revert this!
                </SweetAlert>
            ) : null}
        </div>
    )
}

export default Categories