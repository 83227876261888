import React, { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom";
import { FiLinkedin } from 'react-icons/fi';
import { FiFacebook } from 'react-icons/fi';
import { FiTwitter } from 'react-icons/fi';
import { FiInstagram } from 'react-icons/fi';
import HttpClient from "../../utils/HttpClient"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { reactLocalStorage } from 'reactjs-localstorage';
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../firbase';


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [allEntry, setAllEntry] = useState([]);

  let navigate = useNavigate();


  const sendFormData = async (e) => {
    e.preventDefault();
    const newEntry = { email: email, password: password };

    if (email != "" && password != "") {
      // let dataSend = {
      //   email,
      //   password
      // }
      // console.log(dataSend)
      // console.log("result", result)
      try  {
        const result = await signInWithEmailAndPassword(auth,email,password)
        toast.success("Login successfully")
        reactLocalStorage.setObject("adminData", auth.currentUser.email);
        reactLocalStorage.setObject("loginstatus", true);
        // reactLocalStorage.setObject("logintoken", result.data.token);
        setTimeout(function () {
          navigate("/")
        }, 2000);

      }
      catch {
        toast.error("something error", "error");

      }
      // alert("Login successFully");
      // setAllEntry([newEntry]);
    } else {
      alert("Login Faliure");
    }


  }
  useEffect(() => {
    console.log("data", allEntry);
  }, [allEntry])
  return (
    <>
      <ToastContainer />
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="login-card">
                <form className="theme-form login-form" onSubmit={sendFormData}>
                  <h4>Login</h4>
                  <h6>Welcome back! Log in to your account.</h6>
                  <div className="form-group">
                    <label>Email Address</label>
                    <div className="input-group"><span className="input-group-text"><i className="icon-email" /></span>
                      <input
                        className="form-control p_input"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                        required
                        placeholder="Test@gmail.com" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="input-group"><span className="input-group-text"><i className="icon-lock" /></span>
                      <input
                        className="form-control"
                        type="password"
                        name="login[password]"
                        required placeholder="*********"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="show-hide"><span className="show"></span></div>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="checkbox">
                      <input id="checkbox1" type="checkbox" />
                      <label className="text-muted" htmlFor="checkbox1">Remember password</label>
                    </div><a className="link" href="forget-password.html">Forgot password?</a>
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary btn-block" type="submit">Sign in</button>
                  </div>
                  <div className="login-social-title">
                    <h5>Sign in with</h5>
                  </div>
                  <div className="form-group">
                    <ul className="login-social">
                      <li><a href="https://www.linkedin.com/login" target="_blank"><FiLinkedin /></a></li>
                      <li><a href="https://www.linkedin.com/login" target="_blank"><FiTwitter /></a></li>
                      <li><a href="https://www.linkedin.com/login" target="_blank"><FiFacebook /></a></li>
                      <li><a href="https://www.instagram.com/login" target="_blank"><FiInstagram /></a></li>
                    </ul>
                  </div>
                  <p>Don't have account?<a className="ms-2" href="sign-up.html">Create Account</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login