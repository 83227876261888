import React, { useEffect, useState } from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    useNavigate,
  } from "react-router-dom";
import Login from '../Pages/Authentication/login';
import Dashboard from '../Pages/Dashboard/index';

import { reactLocalStorage } from 'reactjs-localstorage';
import PrivateRoutes from './PrivateRoutes';
import AddQr from "../Pages/AddQr/index"


const Index = () => {
  return (
    <> <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Dashboard />} />
      
        <Route path='/add-qr' element={<AddQr/>}/>

        </Route>
        <Route path="/login" element={<Login />} />

        

      </Routes>
    </BrowserRouter>
   
      </>
  )
}

export default Index